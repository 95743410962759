<template>
  <div>
    <el-card>
      <!-- 姓名 岗位 编号 工序  -->
      <el-form :inline="true" :model="searchForm" class="demo-form-inline" @submit.native.prevent>
        <el-row>
          <el-col :md="8">
            <el-form-item label="员工姓名：">
              <el-input size="small" v-model="searchForm.stff_name" placeholder="员工姓名" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="所属部门:">
              <selectSectionType ref="selectSectionType" @selectStatusRow="getStatusVal2" />
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" class="vg_ml_16" @click="queryPro" icon="el-icon-search">查询</el-button>
              <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                ><i class="el-icon-refresh-right"></i> 刷新</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="vg_mtb_16">
          <el-col>
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-plus" @click="addProc">新增</el-button>
            </el-form-item>
            <el-form-item class="vg_ml_10">
              <el-button type="danger" size="small" icon="el-icon-delete" @click="delProc">删除</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        class="vg_cursor"
        border
        :data="procList"
        style="width: 100%"
        @row-dblclick="dbClickJp"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="index" label="序号" align="center" width="58"></el-table-column>
        <el-table-column type="selection" align="center"></el-table-column>
        <el-table-column prop="dept_name" label="所属部门"> </el-table-column>
        <el-table-column prop="stff_no" label="员工编号"></el-table-column>
        <el-table-column prop="stff_name" label="员工姓名"></el-table-column>
        <el-table-column prop="user_name" label="账户名"></el-table-column>
        <el-table-column prop="proc_codes" label="工序" show-overflow-tooltip :formatter="formatLeavTypes"></el-table-column>
        <el-table-column prop="proc_defa" label="默认工序" :formatter="formatLeavType"></el-table-column>

        <el-table-column prop="stff_job" label="员工岗位">
          <template slot-scope="scope">
            <span v-if="scope.row.stff_job">
              {{ scope.row.stff_job }}
            </span>
            <span v-else class="vg_9f9a9a">暂无岗位</span>
          </template>
        </el-table-column>
        <el-table-column prop="dept_team_name" label="小组名称">
          <template slot-scope="scope">
            <span v-if="scope.row.dept_team_name">
              {{ scope.row.dept_team_name }}
            </span>
            <span v-else class="vg_9f9a9a">暂无小组</span>
          </template>
        </el-table-column>
      </el-table>

      <pubPagination :totalPage="total" @changePageSearch="changePageSearch" ref="pubPagination"></pubPagination>
    </el-card>
  </div>
</template>
<script>
import { get, post } from '@api/request';
import { procAPI } from '@api/modules/proc';
import pubPagination from '@/components/common/pubPagination';
import selectSectionType from '@/views/component/selectSectionType';
export default {
  name: 'procList',
  data() {
    return {
      procList: [],
      searchForm: {
        page_no: 1,
        stff_name: null,
        dept_id: null
      },
      total: 0,
      multipleSelection: [],
      selectRefuDisabled: false, //所属部门 是否可选
      isDyj: true,
      beloDeptId: null
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === '/proc_list') {
        this.getProcList();
      }
    }
  },
  methods: {
    //判断是否 是DYJ员工
    getDepartment() {
      let userInfo = this.$cookies.get('userInfo');
      if (userInfo.dept_id === 59 || userInfo.dept_id === 60) {
        this.searchForm.dept_id = userInfo.dept_id + '';
        this.beloDeptId = userInfo.dept_id + '';
        this.isDyj = true;
      } else {
        this.searchForm.dept_id = null;
        this.isDyj = false;
      }
    },
    //获取列表数据
    getProcList() {
      get(procAPI.get_procs, this.searchForm)
        .then(res => {
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg);
          }
          this.procList = res.data.data.list;
          this.total = res.data.data.total;
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //分页查询
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.getProcList();
    },
    //搜索查询
    queryPro() {
      this.searchForm.page_no = 1;
      this.getProcList();
    },
    buttonRefresh() {
      this.searchForm = {
        page_no: 1,
        stff_name: null
      };
      if (this.isDyj) {
        this.searchForm.dept_id = this.beloDeptId;
      } else {
        this.searchForm.dept_id = null;
        this.$refs.selectSectionType.value2 = '全部';
      }
      this.currentPage = 1;
      this.getProcList();
      this.$refs.pubPagination.currentPage = 1;
    },
    //双击跳转
    dbClickJp(row) {
      this.jump(`/proc_edit?id=${row.proc_id}`);
    },
    //新增
    addProc() {
      this.jump('/proc_add');
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delProc() {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除该员工工序, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            let proc_id_list = [];
            for (let i in this.multipleSelection) {
              proc_id_list.push(this.multipleSelection[i].proc_id);
            }

            post(procAPI.destroy_proc_by_ids, { proc_id_list })
              .then(res => {
                if (res.data.code !== 0) {
                  return this.$message.error(res.data.msg);
                }
                this.$message.success(res.data.msg);
                this.getProcList();
              })
              .catch(res => {});
          })
          .catch(() => {
            this.$message.info('已取消删除');
          });
      } else {
        this.$message.warning('至少选择一条数据');
      }
    },
    // 默认工序
    formatLeavType(row) {
      if (row.proc_defa === 'manu') {
        return '手工';
      } else if (row.proc_defa === 'insp') {
        return '检验';
      } else if (row.proc_defa === 'mase') {
        return '机缝';
      } else if (row.proc_defa === 'embr') {
        return '电绣';
      } else if (row.proc_defa === 'stam') {
        return '印花';
      } else if (row.proc_defa === 'lase') {
        return '激光';
      } else if (row.proc_defa === 'comp') {
        return '复合';
      } else if (row.proc_defa === 'open') {
        return '开版';
      } else if (row.proc_defa === 'stpr') {
        return '备料';
      } else if (row.proc_defa === 'emcd') {
        return '制卡';
      }
    },
    formatLeavTypes(row) {
      let str = '';
      row.proc_codes.split(',').forEach(item => {
        if (item === 'manu') {
          str += '、手工';
        } else if (item === 'insp') {
          str += '、检验';
        } else if (item === 'mase') {
          str += '、机缝';
        } else if (item === 'embr') {
          str += '、电绣';
        } else if (item === 'stam') {
          str += '、印花';
        } else if (item === 'lase') {
          str += '、激光';
        } else if (item === 'comp') {
          str += '、复合';
        } else if (item === 'open') {
          str += '、开版';
        } else if (item === 'stpr') {
          str += '、备料';
        } else if (item === 'emcd') {
          str += '、制卡';
        }
      });
      return str.substr(1);
    },
    //查询范围
    getStatusVal2(val) {
      this.searchForm.dept_id = val;
    }
  },
  created() {
    this.getDepartment();
    this.getProcList();
    this.getDepartment();
  },
  components: {
    pubPagination,
    selectSectionType
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.vg_ml_32 {
  margin-left: 32px;
}
.vg_ml_10 {
  margin-left: 10px;
}
.vg_mtb_16 {
  margin: 16px 0;
}
.el-table {
  // margin-top: 15px;
}
</style>
